import React from 'react';
import styled from 'styled-components';
import { Grid } from 'antd';

const TextContainer = styled.div`
  position: relative;
  height: ${({ mediumOrLarger }) => mediumOrLarger ? '46px' : '34px'};
  font-size: ${({ mediumOrLarger }) => mediumOrLarger ? '32px' : '24px'};
  line-height: ${({ mediumOrLarger }) => mediumOrLarger ? '46px' : '34px'};
  & > div {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  & > div:nth-child(1) {
    font-family: 'rig-shaded-bold-face';
  }
  & > div:nth-child(2) {
    font-family: 'rig-shaded-bold-shadow';
    color: ${({ theme }) => theme.highlightPrimary};
    user-select: none;
  }
  & > div:nth-child(3) {
    font-family: 'rig-shaded-bold-shading-fine';
    color: ${({ theme }) => theme.highlightSecondary};
    user-select: none;
  }
`;

export const TripleText = ({ contents, mediumOrLarger }) => {
  return (
    <TextContainer mediumOrLarger={mediumOrLarger}>
      <div>{contents}</div>
      <div>{contents}</div>
      <div>{contents}</div>
    </TextContainer>
  );
};

export default TripleText;
