import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Timeline } from 'antd';

import Page from '../components/page';

import storyEntries from '../story-entries.json';

const StyledTimeline = styled(Timeline)`
  .ant-timeline-item {
    padding-bottom: 2px;
    margin-bottom: 24px;
  }

  .ant-timeline-item-label {
    font-family: 'montserrat';
    font-size: 20px;
    line-height: 24px;
    font-style: italic;
    margin-top: 10px;
  }

  .ant-timeline-item-head-custom, .ant-timeline-item-tail {
    width: 4px;
    background-color: ${({ theme }) => theme.foregroundPrimary};
  }
  .ant-timeline-item-tail {
    margin-top: -1px;
    margin-left: -1px;
  }

  .ant-timeline-item-content {
    padding: 10px 20px;
  }

  .ant-timeline-item-left .ant-timeline-item-content {
    background: linear-gradient(90deg, ${({ theme }) => theme.highlightSecondary} 0%, rgba(255, 255, 255, 0) 50%);
    margin-left: 7px;
    top: 1px;
  }

  .ant-timeline-item-right .ant-timeline-item-content {
    background: linear-gradient(270deg, ${({ theme }) => theme.highlightPrimary} 0%, rgba(255, 255, 255, 0) 50%);
    width: calc(50% - 1px) !important;
    top: 1px;
  }

  .imageWrapper {
    position: relative;
    display: block;
    height: 100px;
    img {
      transition: all 0.3s ease-in-out;
    }
    img:nth-child(1) {
      position: absolute;
      top: 10px;
    }
    img:nth-child(2) {
      position: absolute;
      top: 18px;
      filter: brightness(0%);
      z-index: -99;
    }
    &:hover img {
      height: 100px;
      width: 100px;
    }
    &:hover img:nth-child(1) {
      top: 0px;
    }
    &:hover img:nth-child(2) {
      top: 6px;
    }
  }

  .ant-timeline-item-right {
    .imageWrapper {
      img:nth-child(1) {
        left: 10px;
      }
      img:nth-child(2) {
        left: 18px;
      }
      &:hover img:nth-child(1) {
        left: 0px;
      }
      &:hover img:nth-child(2) {
        left: 6px;
      }
    }
  }
  .ant-timeline-item-left {
    .imageWrapper {
      img:nth-child(1) {
        right: 10px;
      }
      img:nth-child(2) {
        right: 18px;
      }
      &:hover img:nth-child(1) {
        right: 0px;
      }
      &:hover img:nth-child(2) {
        right: 4px;
      }
    }
  }

  .ant-timeline-item:last-child {
    display: none;
  }
`;

const TimelineText = styled.div`
  font-family: 'montserrat';
  font-size: 16px;
  line-height: 20px;
`;

const TimelineTitle = styled(TimelineText)`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-decoration: underline;
  margin-bottom: 8px;
`;

export const Story = () => {
  const renderLabel = (labels, index) => (
    <div index={index}>
      {labels?.map((label) => {
        if (typeof label === 'string') {
          return <div>{label}</div>
        } else if (label?.type === 'image') {
          const Wrapper = label?.link ? Link : React.div
          return (
            <Wrapper to={label.link} state={label.linkState} className="imageWrapper">
              <img {...label} height={80} width={80} />
              <img {...label} height={80} width={80} />
            </Wrapper>
          );
        // TODO: More types?
        } else {
          return null;
        }
      })}
    </div>
  )

  return (
    <Page>
      <StyledTimeline
        mode="alternate"
        items={storyEntries.map((timelineEntry, index) => ({
          dot: <div />,
          label: renderLabel(timelineEntry.labels, index),
          children: (
            <p>
              <TimelineTitle>{timelineEntry.title}</TimelineTitle>
              <TimelineText>{timelineEntry.description}</TimelineText>
            </p>
          )
        }))}
      />
    </Page>
  );
} 

export default Story;
