import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Grid } from 'antd';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'montserrat';
`;

const Background = styled.div`
  position: fixed;
  top: -60%;
  left: -60%;
  width: 250%;
  height: 250%;
  transform: rotate(-45deg);
  user-select: none;
  z-index: -99999;
  opacity: 0.10;
  font-family: 'rig-solid-bold-outline';
  font-size: 40px;
  color: ${({ theme }) => theme.foregroundPrimary};
`;

const PageContents = styled(PageContainer)`
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
`;

const ColorScheme = styled.div`
  position: fixed;
  border: 2px solid black;
  border-radius: 3px;
  width: 25px;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  box-shadow: 4px 4px ${({ theme }) => theme.foregroundPrimary};
`;

const ColorBlock = styled.div`
  position: relative;
  height: 25px;
  width: 25px;
  background-color: ${({ color }) => color};
  transition: height 200ms, width 200ms;
  & > div {
    position: absolute;
    right: 45px;
    top: 11.5px;
    color: ${({ theme }) => theme.foregroundPrimary};
    opacity: 0;
    transition: opacity 200ms;
    font-family: 'rig-shaded-bold-face';
  }
  :hover {
    height: 40px;
    width: 40px;
    border: 2px solid black;
    margin: -2px;
    border-radius: 3px;
    z-index: 99;
    & > div {
      opacity: 1;
    }
  }
`;

export const Page = ({ children, title, colors = ['#000000', '#252529', '#e8e8e8', '#da8fbe', '#93e9be'] }) => {
  const screens = Grid.useBreakpoint();

  return (
    <PageContainer>
      <Background>
        {new Array(20000).fill('HIRE ME').join(' ')}
      </Background>
      <PageContents>
        {children}
      </PageContents>
      {colors?.length > 0 && screens.lg && (
        <ColorScheme>
          {colors.map((color) => (
            <ColorBlock color={color} key={color}>
              <div>{color}</div>
            </ColorBlock>
          ))}
        </ColorScheme>
      )}
    </PageContainer>
  );
};

export default Page;
