import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Row, Col, Grid, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

import Page from '../components/page';

import portfolioEntries from '../portfolio-entries.json';

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const PortfolioImage = styled.div`
  position: relative;
  margin: 0px 40px 80px 40px;
  width: 320px;
  min-width: 320px;
  height: 320px;
  box-sizing: border-box;
  box-shadow: 4px 4px ${({ theme }) => theme.foregroundPrimary};
  border-radius: 16px;
  border: 4px solid ${({ theme }) => theme.foregroundPrimary};
  cursor: pointer;
  overflow: hidden;
  &:hover > div {
    opacity: 1;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 320px;
  width: 320px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(218, 143, 190, 0.5) 0%, rgba(147, 233, 190, 0.5) 100%), rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  font-family: 'montserrat';
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  padding: 20px;
  color: white;
`;

const Subtitle = styled.div`
  font-family: 'montserrat';
  font-size: 24px;
  line-height: 30px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  &:hover > div {
    opacity: 1;
  }
`;

const CloseButton = styled(FontAwesomeIcon)`
  height: 42px;
  width: 42px;
`;

const CloseButtonHover = styled.div`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  height: 36px;
  width: 36px;
  top: 7px;
  right: -1px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.highlightPrimary};
  z-index: -999;
`;

const Block = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'montserrat';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
  // max-height: 360px;
`;

const BlockImage = styled.div`
  width: ${({ large }) => large ? 400 : 320}px;
  min-width: ${({ large }) => large ? 400 : 320}px;
  height: ${({ large }) => large ? 400 : 320}px;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: 8px 8px ${({ theme }) => theme.foregroundPrimary};
  &:hover {
    box-shadow: 4px 4px ${({ theme }) => theme.foregroundPrimary};
  }
  ${({ onClick, theme }) => onClick ? '' : `
    box-shadow: 4px 4px ${theme.foregroundPrimary};
  `}
  border-radius: 16px;
  border: 4px solid ${({ theme }) => theme.foregroundPrimary};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'normal'};
  overflow: hidden;
  &:hover > div {
    opacity: 1;
  }
  .before-after-slider,
  .before-after-slider__first-photo-container,
  .before-after-slider__second-photo-container,
  .before-after-slider__delimiter {
    height: ${({ large }) => large ? 400 : 320}px;
    img {
      height: ${({ large }) => large ? 400 : 320}px;
    }
  }
`;

export const Portfolio = ({ match }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const screens = Grid.useBreakpoint();
  const [activePortfolioEntry, setActivePortfolioEntry] = useState(portfolioEntries[state?.entryId] || null);
  const [activeModal, setActiveModal] = useState(null);

  const renderBlock = (block) => {
    switch (block.type) {
      case "text":
        return <div>{block.text}</div>;
      case "image":
        if (block.noborder) {
          return (
            <img style={{ maxHeight: 400, maxWidth: "100%" }} src={block.src} />
          );
        }
        return (
          <BlockImage large={screens.sm} onClick={() => screens.sm && setActiveModal(block.src)}>
            <img style={{ width: screens.sm ? 400 : 320, height: screens.sm ? 400 : 320, objectFit: "cover" }} src={block.src} />
          </BlockImage>
        );
      case "frame":
        return <iframe src={block.frameSource} style={{ width: "100%", maxWidth: block.width || 846, height: block.height || 360 }} />;
      case "beforeAfter":
        return (
          <BlockImage large={screens.sm}>
            <ReactBeforeSliderComponent
              style={{ height: 400 }}
              firstImage={{ imageUrl: block.image2 }}
              secondImage={{ imageUrl: block.image1 }}
            />
          </BlockImage>
        );
    }
  };

  return (
    <Page>
      <Content>
        {portfolioEntries?.map((portfolioEntry) => (
          <PortfolioImage onClick={() => setActivePortfolioEntry(portfolioEntry)}>
            <img style={{ width: 320, height: 320, objectFit: "cover" }} src={portfolioEntry.image} />
            <Overlay>
              <img width="100" height="100" src={portfolioEntry.icon} />
              {portfolioEntry.name}
            </Overlay>
          </PortfolioImage>
        ))}
      </Content>
      <Drawer
        placement="bottom"
        title={<>
          <div>
            <div>{activePortfolioEntry?.name}</div>
            <Subtitle>{activePortfolioEntry?.subtitle}</Subtitle>
          </div>
          <CloseButtonContainer>
            <CloseButtonHover />
            <CloseButton
              icon={faSquareXmark}
              onClick={() => {
                setActivePortfolioEntry(null);
                navigate('/portfolio');
              }}
            />
          </CloseButtonContainer>
        </>}
        closable={false}
        open={activePortfolioEntry}
        contentWrapperStyle={{ height: '100%', backgroundColor: `${activePortfolioEntry?.backgroundColor}cc`, backdropFilter: "blur(40px)" }}
      >
        <Page colors={activePortfolioEntry?.colors}>
          <Row>
            {activePortfolioEntry?.blocks?.map((block, index) => (
              <Block xs={block?.span > 6 ? 24 : block?.span * 2 || 24} lg={block?.span || 24} key={index}>
                {renderBlock(block)}
              </Block>
            ))}
          </Row>
        </Page>
      </Drawer>
      <Modal open={activeModal} onCancel={() => setActiveModal(null)} closable={false} footer={null} maskClosable>
        <img src={activeModal} style={{ maxWidth: "calc(100vw - 20px)", maxHeight: "calc(100vh - 20px)" }} />
      </Modal>
    </Page>
  );
} 

export default Portfolio;
