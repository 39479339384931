import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Row, Col, Grid } from 'antd';
import TripleText from './triple-text';

const textAnimationSmall = keyframes`
  4% { top: 0 }
  16% { top: -34px }
  24% { top: -34px }
  36% { top: -68px }
  44% { top: -68px }
  56% { top: -102px }
  64% { top: -102px }
  76% { top: -136px }
  84% { top: -136px }
  96% { top: 0 }
`;

const textAnimationMediumOrLarger = keyframes`
  4% { top: 0 }
  16% { top: -46px }
  24% { top: -46px }
  36% { top: -92px }
  44% { top: -92px }
  56% { top: -138px }
  64% { top: -138px }
  76% { top: -184px }
  84% { top: -184px }
  96% { top: 0 }
`;

const Container = styled.div`
  width: ${({ width }) => width}px;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover > div {
    animation-name: ${({ mediumOrLarger }) => mediumOrLarger ? textAnimationMediumOrLarger : textAnimationSmall};
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TextSpinner = ({ words, width }) => {
  const screens = Grid.useBreakpoint();

  return (
    <Container width={width} mediumOrLarger={screens.md}>
      <TextContainer>
        {words.map((word, index) => (
          <TripleText key={`${word}-${index}`} contents={`${word}.`} mediumOrLarger={screens.md} />
        ))}
      </TextContainer>
    </Container>
  );
};

export default TextSpinner;
