import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider, keyframes } from 'styled-components';

import routes from './routes';
import theme from "./theme";
import App from './App';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.backgroundPrimary};
    color: ${({ theme }) => theme.foregroundPrimary};
  }

  h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 44px;
    margin-top: 0px;
  }

  h2 {
    text-decoration: underline;
    font-size: 32px;
    line-height: 38px;
  }

  p {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  a {
    color: ${({ theme }) => theme.foregroundPrimary};
    transition: color 200ms;
    font-family: 'rig-shaded-bold-face';
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      animation: linkAnimation 1s infinite;
    }
  }

  .text-highlight {
    color: ${({ theme }) => theme.highlightPrimary};
  }

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
  .ant-drawer-content {
    background-color: unset !important;
  }
  .ant-drawer-header {
    border-bottom: 0px !important;
    padding: 27px 27px 0px 27px !important;
    .ant-drawer-title {
      position: relative;
      width: 100%;
      font-family: 'rig-shaded-bold-face';
      font-size: 32px !important;
      line-height: 46px !important;
      display: flex;
      flex-direction: row;
    }
  }
  .ant-modal, .ant-modal-content {
    background-color: unset !important;
    box-shadow: unset !important;
  }
  .ant-modal-body {
    display: flex;
    justify-content: center;
  }
`;

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element
    }))
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RouterProvider router={router}>
        <App />
      </RouterProvider>
    </ThemeProvider>
  </React.StrictMode>
);
