import React, { createRef } from 'react';

import Story from './features/story';
import Portfolio from './features/portfolio';
import Interests from './features/interests';

export const routes = [
  { path: "/portfolio", name: "Portfolio", element: <Portfolio />, nodeRef: createRef() },
  { path: "/portfolio/:id", name: "Portfolio", element: <Portfolio />, nodeRef: createRef() },
  { path: "/", name: "Portfolio", element: <Portfolio />, nodeRef: createRef() },
  { path: "/story", name: "Story", element: <Story />, nodeRef: createRef() },
  { path: "/interests", name: "Interests", element: <Interests />, nodeRef: createRef() }
];

export default routes;
