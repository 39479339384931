import React, { createRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Routes, Route, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { Dropdown, Grid, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import TextSpinner from './components/text-spinner';
import TripleText from './components/triple-text';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import routes from './routes';
import _ from 'lodash';

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  padding: 50px 0px;
  box-sizing: border-box;
`;

const ContentContainer = styled.div`
  height: calc(100% - 60px);
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  width: ${({ mediumOrLarger }) => mediumOrLarger ? '100%' : '302px'};
  font-size: ${({ mediumOrLarger }) => mediumOrLarger ? '32px' : '24px'};
  line-height: ${({ mediumOrLarger }) => mediumOrLarger ? '46px' : '34px'};
  font-family: 'rig-shaded-bold-face';
  display: flex;
  flex-direction: ${({ mediumOrLarger }) => mediumOrLarger ? 'row' : 'column'};
  justify-content: center;
  align-items: ${({ mediumOrLarger }) => mediumOrLarger ? 'center' : 'left'};
  & > div {
    display: flex;
    flex-direction: row;
  }
`;

const StyledDropdown = styled(Dropdown)`
  border: 2px solid ${({ theme }) => theme.foregroundPrimary};
  background-color: ${({ theme }) => theme.backgroundPrimary};
  font-size: 32px;
  line-height: 46px;
  padding: 8px 16px 8px 8px;
  width: 262px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  svg {
    height: 22px;
    margin-top: 8px;
  }
`;

const TextContainer = styled.div`
  position: relative;
  height: 46px;
  line-height: 46px;
  width: 262px;
  & > div {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  & > div:nth-child(1) {
    font-family: 'rig-shaded-bold-face';
  }
  & > div:nth-child(2) {
    font-family: 'rig-shaded-bold-shadow';
    color: ${({ theme }) => theme.highlightPrimary};
    user-select: none;
  }
  & > div:nth-child(3) {
    font-family: 'rig-shaded-bold-shading-fine';
    color: ${({ theme }) => theme.highlightSecondary};
    user-select: none;
  }
`;

const EmailMeLink = styled.a`
  position: fixed;
  top: 24px;
  right: 24px;
`;

function App() {
  const location = useLocation();
  const currentOutlet = useOutlet();
  const screens = Grid.useBreakpoint();
  const navigate = useNavigate();
  const theme = useTheme();
  const { nodeRef, name: routeName } = routes.find((route) => route.path === location.pathname) ?? {};

  const MenuOptions = _.uniqBy(routes, 'name').map((route) => (
    <Menu.Item onClick={() => navigate(route.path)} key={route.name} title={route.name} label={route.name}>
      <TripleText contents={route.name} mediumOrLarger={true} />
    </Menu.Item>
  ));

  return (
    <Container>
      <ContentContainer>
        <HeaderContainer>
          {screens.md && (
            <EmailMeLink target="_new" rel="noreferrer" href="mailto:mwardgurman@gmail.com?subject=Please design for me!">Email me</EmailMeLink>
          )}
          <Title mediumOrLarger={screens.md}>
            <div>
              <span>Hi.&nbsp;I'm&nbsp;</span>
              <TextSpinner width={133} words={['Moses', 'Nerdy', 'Tall', 'Eager', 'Sharp']} />
              <span>&nbsp;</span>
            </div>
            <div>
              <span>I'm&nbsp;a&nbsp;</span>
              <TextSpinner width={303} words={['UX/UI\xa0Designer', 'Performer', 'Bad\xa0Dancer', 'Dog\xa0Lover', 'Shower\xa0Singer']} />
            </div>
          </Title>
          <Title mediumOrLarger={screens.md}>
            <div>Take a look at my&nbsp;</div>
            <StyledDropdown
              defaultValue="/portfolio"
              arrow={false}
              dropdownRender={() => (
                <Menu style={{
                  marginTop: -6,
                  borderRadius: 0,
                  fontSize: 32,
                  border: `2px solid ${theme.foregroundPrimary}`,
                  backgroundColor: theme.backgroundPrimary
                }}>
                  {MenuOptions}
                </Menu>
              )}
            >
              <div>
                <TripleText contents={routeName} mediumOrLarger={true} />
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </StyledDropdown>
          </Title>
        </HeaderContainer>
        <SwitchTransition>
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            {(state) => (
              <div ref={nodeRef} className="fade" style={{ paddingTop: 30 }}>
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        </SwitchTransition>
      </ContentContainer>
    </Container>
  );
}

export default App;
