export const theme = {
  foregroundPrimary: '#000000',

  backgroundPrimary: '#e8e8e8',
  
  highlightPrimary: '#da8fbe',
  highlightSecondary: '#93e9be',

  modalBlack: 'rgba(0, 0, 0, 0.5)',
  timelineGray: '#777777',
};

export default theme;
