import React from 'react';
import styled from 'styled-components';
import { Row, Col, Grid } from 'antd';

import Page from '../components/page';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  a {
    color: ${({ theme }) => theme.foregroundPrimary};
    display: block;
    font-size: 20px;
    line-height: 45px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 29px;
  font-weight: 500;
  padding: 0px 20px;
`;

const StyledCol = styled(Col)`
  display: grid;
  justify-content: center;
  &:last-child {
    justify-self: center;
    align-self: center;
  }
`;

const ImageBlock = styled.div`
  min-height: 160px;
  height: 160px;
  min-width: 160px;
  width: 160px;
  margin: 20px 40px;
  border: 2px solid ${({ theme }) => theme.foregroundPrimary};
  border-radius: 16px;
  box-shadow: 4px 4px ${({ theme }) => theme.foregroundPrimary};
  overflow: hidden;
`;

export const Interests = () => {
  const screens = Grid.useBreakpoint();
  return (
    <Page>
      <Container>
        <Title>These are a few of my favorite things:</Title>
        <p>
          <a target="_blank" rel="noreferrer" href="https://vincentgarreau.com/particles.js/#default">particles.js</a>
          <a target="_blank" rel="noreferrer" href="https://robertsspaceindustries.com/">Star Citizen</a>
          <a target="_blank" rel="noreferrer" href="https://trianglify.io/">trianglify.io</a>
          <a target="_blank" rel="noreferrer" href="https://ilikeinterfaces.com/">Interface Love</a>
          <a target="_blank" rel="noreferrer" href="https://datavizproject.com/">Data Viz Project</a>
        </p>
        <Title>These are a few of my favorite people:</Title>
        <Row style={{ maxWidth: 600 }}>
          {["Steph", "Samus", "Zev", "Joseph", "Jeannie"].concat(screens.md ? [""] : []).concat(["Kip"]).map((name, index) => (
            <StyledCol key={`${name}-${index}`} xs={12} md={8}>
              {name && (
                <ImageBlock>
                  <img src={`/${name}.png`} />
                </ImageBlock>
              )}
            </StyledCol>
          ))}
        </Row>
      </Container>
    </Page>
  );
} 

export default Interests;
